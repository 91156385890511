import axios from 'axios';

export const state = {
    user: null,
    token: localStorage.getItem('token') || null,
    passwordToken: null,
    processingPassword: false,
    PasswordFormValues: {
        password: '',
        passwordConfirmation: '',
        email: '',
    },
    userPermissions: [],

}

export const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.token = token;
        localStorage.setItem('token', token);
    },
    clearAuth(state) {
        state.user = null;
        state.token = null;
        localStorage.removeItem('token');
    },
    SET_PROCESSING_PASSWORD(state, status) {
        state.processingPassword = status;
    },
    SET_PASSWORD_FORM_VALUES(state, values) {
        state.PasswordFormValues = values;
    },
    setFormValues(state, formValues) {
        state.formValues = formValues;
    },

      SET_PERMISSIONS(state, permissions) {
        state.userPermissions = permissions;
      },

}

export const getters = {
    isAuthenticated(state) {
        return !!state.user;
    },
    user: state => state.user,
    processingPassword: state => state.processingPassword,
    PasswordFormValues: state => state.PasswordFormValues,
    passwordToken: state => state.passwordToken,


        hasPermission: (state) => (permission) => {
          return state.userPermissions.includes(permission);
        },
     

}

export const actions = {
    async login({ commit }, values) {
        const response = await axios.post('/login', values);
        commit('setUser', response.data.user);
        commit('setToken', response.data.token);

    },
   async fetchPermissions({ commit }) {
        try {
          const response = await axios.get('/user/permissions', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          console.log('Full response:', response.data);
          const permissions = response.data.map(permission => permission.name) || [];
          commit('SET_PERMISSIONS', permissions);
          console.log('User permissions:', permissions);
        } catch (error) {
          console.error('Failed to fetch permissions:', error);
          commit('SET_PERMISSIONS', []); // Ensure it's an array even on error
        }
      },

    async logout({ commit }) {
        await axios.post('/logout');
        commit('clearAuth');
    },
    async fetchUser({ commit, state }) {
        if (state.token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
            try {
                const response = await axios.get('/user');
                commit('setUser', response.data.data);
            } catch (error) {
                commit('clearAuth');
            }
        }
    },
    initializeTokens({ commit }) {
        const passwordToken = new URLSearchParams(window.location.search).get('passwordToken') || '';
        commit('setPasswordToken', passwordToken);
    },
    async submitPassword({ commit, state }, { values }) {
        commit('SET_PROCESSING_PASSWORD', true);
        try {
            await axios.post('/reset-password', {
                password: values.password,
                password_confirmation: values.passwordConfirmation,
                token: state.passwordToken,
                email: values.email,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            });
           // commit('CLEAR_PASSWORD_FORM_VALUES');
           // router.push({ path: '/login' });
        } catch (error) {
            console.error('Error submitting password:', error);
        } finally {
            commit('SET_PROCESSING_PASSWORD', false);
        }
    },
    updatePasswordFormValues({ commit }, values) {
        commit('SET_PASSWORD_FORM_VALUES', values);
    },

    async submitForm(values) {
        await axios.post('/forgot-password', values,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
    },
}