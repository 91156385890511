import { createRouter, createWebHistory } from 'vue-router';
import { ref } from 'vue';
import axios from 'axios';

const routes = [
  {
    path: "/",
    name: "home-page",
    meta: { title: "Home", requiresAuth: true },
    component: () => import("../views/home.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth/Login.vue"),
    meta: { title: "Login" },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      title: "Forget Password", requiresAuth: false,
    },
    component: () => import('../views/Auth/forgot-password.vue')
  },
  {
    path: '/reset-password',
    name: 'create-password',
    meta: {
      title: "Create Password", requiresAuth: false,
    },
    component: () => import('../views/Auth/create-password.vue')
  },
  {
    path: "/profile",
    name: "profile",
    meta: { title: "Profile", requiresAuth: true },
    component: () => import("../views/Profile/ProfileUser.vue"),
  },
  {
    path: "/profile-setting",
    name: "profile-setting",
    meta: { title: "Setting", requiresAuth: true },
    component: () => import("../views/Profile/setting.vue"),
  },
  {
    path: "/employees",
    name: "Employees",
    meta: { title: "Employees", requiresAuth: true },
    component: () => import("../views/Employees/index.vue"),
  },
  {
    path: "/customers",
    name: "CustomerIndex",
    meta: { title: "Customers", requiresAuth: true },
    component: () => import("../views/Customers/index.vue"),
  },
  {
    path: "/customers/create",
    name: "CreateCustomer",
    meta: { title: "Create Customer", requiresAuth: true },
    component: () => import("../views/Customers/create.vue"),
  },
  {
    path: '/customers/:id/edit',
    name: 'EditCustomer',
    meta: { title: "Edit Customer", requiresAuth: true },
    component: () => import("../views/Customers/edit.vue"),
  },
  {
    path: "/roles",
    name: "RolesIndex",
    meta: { title: "Roles", requiresAuth: true },
    component: () => import("../views/Roles/Index.vue"),
  },
  {
    path: "/permissions",
    name: "PermissionsIndex",
    meta: { title: "Permissions", requiresAuth: true },
    component: () => import("../views/Permissions/Index.vue"),
  },
  {
    path: "/assignment",
    name: "PermissionAssignment",
    meta: { title: "PermissionAssignment", requiresAuth: true },
    component: () => import("../views/assignment/Index.vue"),
  },
  {
    path: "/banks",
    name: "BankIndex",
    meta: {
      title: "Banks", requiresAuth: true,
      requiresPermission: 'view-banks'
    },
    component: () => import("../views/Banks/index.vue"),
  },
  {
    path: "/cousins",
    name: "CousinIndex",
    meta: { title: "Cousins", requiresAuth: true },
    component: () => import("../views/Cousins/Index.vue"),
  }, {
    path: "/cities",
    name: "CitiesIndex",
    meta: { title: "Cities", requiresAuth: true },
    component: () => import("../views/Cities/Index.vue"),
  },
  {
    path: "/hear-about-us",
    name: "HearAboutUSIndex",
    meta: { title: "HearAboutUS", requiresAuth: true },
    component: () => import("../views/HearAboutUS/Index.vue"),
  },

  {
    path: "/citizens",
    name: "CitizensIndex",
    meta: { title: "Citizens", requiresAuth: true },
    component: () => import("../views/Citizens/Index.vue"),
  },
  {
    path: '/jobs',
    name: 'JobIndex',
    meta: { title: 'Job', requiresAuth: true },
    component: () => import("../views/Jobs/Index.vue"),
  },
  {
    path: "/statuses",
    name: "StatusesIndex",
    meta: { title: "StatusesIndex", requiresAuth: true },
    component: () => import("../views/Statuses/Index.vue"),
  },
  {
    path: "/holidays",
    name: "HolidaysIndex",
    meta: { title: "HolidaysIndex", requiresAuth: true },
    component: () => import("../views/Holidays/Index.vue"),
  },
  {
    path: "/work-days",
    name: "WorkDaysIndex",
    meta: { title: "WorkDaysIndex", requiresAuth: true },
    component: () => import("../views/WorkDays/Index.vue"),
  },
  {
    path: "/leave-types",
    name: "LeaveTypesIndex",
    meta: { title: "LeaveTypesIndex", requiresAuth: true },
    component: () => import("../views/LeaveTypes/Index.vue"),
  },
  {
    path: "/leave-policies",
    name: "LeavePoliciesIndex",
    meta: { title: "Leave Policies", requiresAuth: true },
    component: () => import("../views/LeavePolicies/Index.vue"),
  },
  {
    path: "/departments",
    name: "DepartmentsIndex",
    meta: { title: "DepartmentsIndex", requiresAuth: true },
    component: () => import("../views/Departments/Index.vue"),
  },

  {
    path: "/companies",
    name: "CompaniesIndex",
    meta: { title: "Companies", requiresAuth: true },
    component: () => import("../views/Companies/Index.vue"),
  },
  {
    path: "/contracts",
    name: "ContractIndex",
    meta: { title: "Contract", requiresAuth: true },
    component: () => import("../views/Contract/index.vue"),
  },
  {
    path: "/contracts/create",
    name: "CreateContract",
    meta: { title: "CreateContract", requiresAuth: true },
    component: () => import("../views/Contract/create.vue"),
  },
  {
    path: '/contracts/:id/edit',
    name: 'EditContract',
    meta: { title: "EditContract", requiresAuth: true },
    component: () => import("../views/Contract/edit.vue"),

  },
  {
    path: '/contracts/:id',
    name: 'ContractDetails',
    component: () => import("../views/Contract/show.vue"),
    props: route => ({ id: route.params.id }),
    meta: {
      title: 'ContractDetails',
      requiresAuth: true
    },
  },
  {
    path: '/contracts/:id/print',
    name: 'ContractPrint',
    component: () => import("../views/Contract/print-contract.vue"),
    props: route => ({ id: route.params.id }),
    meta: {
      title: 'ContractPrint',
      requiresAuth: true
    },
  },
  {
    path: '/contracts/:id',
    name: 'ContractDetails',
    component: () => import("../views/Contract/show-con.vue"),
    props: route => ({ id: route.params.id }),
    meta: {
      title: 'ContractDetails',
      requiresAuth: true
    },
  },
  {
    path: '/contracts/:id/promissory-note',
    name: 'ContractPromissoryNote',
    component: () => import("../views/Contract/promissory-note.vue"),
    props: route => ({ id: route.params.id }),
    meta: {
      title: 'ContractPromissoryNote',
      requiresAuth: true
    },
  },

  {
    path: "/companies",
    name: "CompaniesIndex",
    meta: { title: "Companies", requiresAuth: true },
    component: () => import("../views/Companies/Index.vue"),
  },
  {
    path: "/companies/create",
    name: "CompaniesCreate",
    meta: { title: "Create Company", requiresAuth: true },
    component: () => import("../views/Companies/Create.vue"),
  },
  {
    path: "/companies/:id/edit",
    name: 'EditCompany',
    meta: { title: "Edit Company", requiresAuth: true },
    component: () => import("../views/Companies/Edit.vue"),
  },
  {
    path: "/inventory-stock-locations",
    name: "InventoryStockLocation",
    meta: { title: "Inventory Locations", requiresAuth: true },
    component: () => import("../views/InventoryStockLocation/Index.vue"),
  },
  {
    path: "/inventory-items",
    name: "InventoryItems",
    meta: { title: "Inventory Items", requiresAuth: true },
    component: () => import("../views/InventoryItems/Index.vue"),
  },
  {
    path: "/inventory-suppliers",
    name: "InventorySuppliers",
    meta: { title: "Inventory Suppliers", requiresAuth: true },
    component: () => import("../views/InventorySuppliers/Index.vue"),
  },
  {
    path: "/inventory-item-quantities",
    name: "InventoryQuantities",
    meta: { title: "inventory item quantities", requiresAuth: true },
    component: () => import("../views/InventoryItemQuantities/Index.vue"),
  },
  {
    path: "/inventory-invoices",
    name: "InventoryInvoicesIndex",
    meta: { title: "Inventory Invoice", requiresAuth: true },
    component: () => import("../views/InventoryInvoices/Index.vue"),
  }, {
    path: "/inventory-invoices/create",
    name: "InventoryInvoicesCreate",
    meta: { title: "Create Inventory Invoice", requiresAuth: true },
    component: () => import("../views/InventoryInvoices/Create.vue"),
  },
  {
    path: "/inventory-invoices/:id/edit",
    name: "EditInventoryInvoice",
    meta: { title: "Edit Inventory Invoice", requiresAuth: true },
    component: () => import("../views/InventoryInvoices/Edit.vue"),
  },
  {
    path: "/Judiciarys/create/:id",
    name: "CreateJudiciary",
    meta: { title: "Create Judiciary", requiresAuth: true },
    component: () => import("../views/Judiciarys/Create.vue"),
  },

  {
    path: "/judiciarys/:id/edit",
    name: "EditJudiciary",
    meta: { title: "Edit Judiciary", requiresAuth: true },
    component: () => import("../views/Judiciarys/Edit.vue"),
  },


  {
    path: "/judiciarys",
    name: "IndexJudiciary",
    meta: { title: "Index Judiciary", requiresAuth: true },
    component: () => import("../views/Judiciarys/Index.vue"),
  },
  {
    path: "/contracts/legal-departments",
    name: "LegalDepartmentsIndex",
    meta: { title: "Legal Departments", requiresAuth: true },
    component: () => import("../views/LegalDepartment/Index.vue"),
  },
  {
    path: '/judiciarys/:id/print',
    name: 'JudiciaryPrint',
    component: () => import("../views/Judiciarys/print-judiciarys.vue"),
    props: route => ({ id: route.params.id }),
    meta: {
      title: 'JudiciaryPrint',
      requiresAuth: true
    },
  },


];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const userPermissions = ref([]);
const fetchPermissions = async () => {
  try {
    const response = await axios.get("/user/permissions", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log('Full response:', response.data); // طباعة الاستجابة الكاملة
    userPermissions.value = response.data.map(permission => permission.name) || [];
    console.log('User permissions:', userPermissions.value);
  } catch (error) {
    console.error('Failed to fetch permissions:', error);
    userPermissions.value = []; // Ensure it's an array even on error
  }
};

const hasPermission = (permission) => {
  return userPermissions.value.includes(permission);
};
window.onload = async () => {
  await fetchPermissions();
  console.log('Has permission "view-banks":', hasPermission('view-banks'));
};

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');

  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    // Redirect to login if not authenticated
    return next({ name: 'login' });
  }

  // Check for permissions if required
  if (to.meta.requiresPermission) {
    if (!userPermissions.value.length) {
      await fetchPermissions();
    }
    if (!hasPermission(to.meta.requiresPermission)) {
      return next('/'); // Redirect to home or another page if permission is denied
    }
  }

  next(); // Ensure next is called to proceed with navigation
});


export default router;
