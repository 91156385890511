import axios from 'axios';

export default {
  namespaced: true,
  state: {
    statuses: [],
    cities: [],
    jobs: [],
    jobTypes: [],
    cousins: [],
    hearAboutUsList: [],
    banks: [],
    citizens: [],
    sellers: [],
    followers: [],
    customers: [],
    companies: [],
    inventoryItems: [],
    suppliers: [],
    inventoryLocations: [],
    users: [],
    departments: [],
    leaveTypes: [],
    permissions: [],
    roles: [],
    courts: [],
    judiciaryInformAddress: [],
    judiciaryTypes: [],
    lawyers: [],
  },

  mutations: {
    setStatuses(state, statuses) {
      state.statuses = statuses;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setJobs(state, jobs) {
      state.jobs = jobs;
    },
    setJobTypes(state, jobTypes) {
      state.jobTypes = jobTypes;
    },
    setCousins(state, cousins) {
      state.cousins = cousins;
    },
    setHearAboutUsList(state, hearAboutUsList) {
      state.hearAboutUsList = hearAboutUsList;
    },
    setBanks(state, banks) {
      state.banks = banks;
    },
    setCitizens(state, citizens) {
      state.citizens = citizens;
    },
    setSellers(state, sellers) {
      state.sellers = sellers;
    },
    setFollowers(state, followers) {
      state.followers = followers;
    },
    setContractCustomers(state, customers) {
      state.customers = customers;
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    setInventoryItems(state, inventoryItems) {
      state.inventoryItems = inventoryItems;
    },
    setSuppliers(state, suppliers) {
      state.suppliers = suppliers;
    },
    setInventoryLocations(state, inventoryLocations) {
      state.inventoryLocations = inventoryLocations;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setDepartments(state, departments) {
      state.departments = departments;
    },
    setLeaveTypes(state, leaveTypes) {
      state.leaveTypes = leaveTypes;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setCourts(state, courts) {
      state.courts = courts;
    },
    setJudiciaryInformAddress(state, judiciaryInformAddress) {
      state.judiciaryInformAddress = judiciaryInformAddress;
    },
    setJudiciaryTypes(state, judiciaryTypes) {
      state.judiciaryTypes = judiciaryTypes;
    },
    setLawyers(state, lawyers) {
      state.lawyers = lawyers;
    }
  },

  actions: {
    async fetchLookups({ commit }) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const requests = [
          axios.get('/lookups/statuses', { headers }),
          axios.get('/lookups/cities', { headers }),
          axios.get('/lookups/jobs', { headers }),
          axios.get('/lookups/job-types', { headers }),
          axios.get('/lookups/cousins', { headers }),
          axios.get('/lookups/hear-about-us', { headers }),
          axios.get('/lookups/banks', { headers }),
          axios.get('/lookups/citizens', { headers }),
          axios.get('/lookups/sellers', { headers }),
          axios.get('/lookups/followers', { headers }),
          axios.get('/lookups/contract-customers', { headers }),
          axios.get('/lookups/companies', { headers }),
          axios.get('/lookups/inventory-items', { headers }),
          axios.get('/lookups/inventory-suppliers', { headers }),
          axios.get('/lookups/inventory-locations', { headers }),
          axios.get('/lookups/users', { headers }),
          axios.get('/lookups/departments', { headers }),
          axios.get('/lookups/leave-types', { headers }),
          axios.get('/lookups/permissions', { headers }),
          axios.get('/lookups/roles', { headers }),
          axios.get('/lookups/courts', { headers }),
          axios.get('/lookups/judiciary-inform-address', { headers }),
          axios.get('/lookups/judiciary-types', { headers }),
          axios.get('/lookups/lawyers', { headers }),
        ];
        const responses = await Promise.allSettled(requests);

        responses.forEach((response, index) => {
          if (response.status === 'fulfilled') {
            const lookupData = response.value.data.data;
            switch (index) {
              case 0: commit('setStatuses', lookupData); break;
              case 1: commit('setCities', lookupData); break;
              case 2: commit('setJobs', lookupData); break;
              case 3: commit('setJobTypes', lookupData); break;
              case 4: commit('setCousins', lookupData); break;
              case 5: commit('setHearAboutUsList', lookupData); break;
              case 6: commit('setBanks', lookupData); break;
              case 7: commit('setCitizens', lookupData); break;
              case 8: commit('setSellers', lookupData); break;
              case 9: commit('setFollowers', lookupData); break;
              case 10: commit('setContractCustomers', lookupData); break;
              case 11: commit('setCompanies', lookupData); break;
              case 12: commit('setInventoryItems', lookupData); break;
              case 13: commit('setSuppliers', lookupData); break;
              case 14: commit('setInventoryLocations', lookupData); break;
              case 15: commit('setUsers', lookupData); break;
              case 16: commit('setDepartments', lookupData); break;
              case 17: commit('setLeaveTypes', lookupData); break;
              case 18: commit('setPermissions', lookupData); break;
              case 19: commit('setRoles', lookupData); break;
              case 20: commit('setCourts', lookupData); break;
              case 21: commit('setJudiciaryInformAddress', lookupData); break;
              case 22: commit('setJudiciaryTypes', lookupData); break;
              case 23: commit('setLawyers', lookupData); break;
              default: break;
            }
          } else {
            console.error('Failed to fetch lookup:', response.reason);
          }
        });

      } catch (error) {
        console.error('Failed to fetch lookups:', error);
      }
    }
  }
}
